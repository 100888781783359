import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BsFillXDiamondFill,
  BsFillArrowDownSquareFill,
  BsFillArrowUpSquareFill,
  BsFillPersonCheckFill,
} from "react-icons/bs";
import { useContext, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import SocialLogin from "../Components/SocialLogin";

const SignUp = ({ mstat, messageback }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [terms, setTerms] = useState(true);
  const [checkForm, setCheckForm] = useState(false);

  let navigate = useNavigate();

  const toggle = () => {
    if (terms === false) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };

  let sendData = async () => {
    const api_userName = document.getElementById("userName").value;
    const api_nameName = document.getElementById("nameName").value;
    const api_email = document.getElementById("email").value;
    const api_password = document.getElementById("password").value;
    const api_password2 = document.getElementById("password2").value;

    if (
      api_password === api_password2 &&
      api_password.length > 6 &&
      api_userName !== "" &&
      api_userName.length > 6 &&
      api_nameName !== "" &&
      api_nameName.length > 1 &&
      api_email !== "" &&
      api_email.length > 6
    ) {
      console.log("data sent from front");
      alert("Please Wait For a Response.");

      // fetch("http://127.0.0.1:8000/reactMakeUser", {
      fetch("https://pertinacity1.pythonanywhere.com/reactMakeUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },

        body: JSON.stringify({
          api_userName: api_userName,
          api_nameName: api_nameName,
          api_email: api_email,
          api_password: api_password,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("data", data);
          if (data === "SomethingWrong") {
            messageback("Error! Duplicate Username or Email");
            mstat();
          } else {
            messageback("Account Created: " + api_userName);
            mstat();
            navigate("/login");
          }
        });
    } else {
      messageback("Error! Form Incorrect");
      mstat();
    }
  };

  const disableBtnProps = {};
  if (checkForm === false) {
    disableBtnProps.disabled = true;
  } else {
    disableBtnProps.disabled = false;
  }

  function checkBox() {
    const checkOne = document.getElementById("checkOne").checked;
    const checkTwo = document.getElementById("checkTwo").checked;
    if (checkOne === true && checkTwo === true) {
      setCheckForm(true);
    } else {
      setCheckForm(false);
    }
  }

  return (
    <div className="container">
      <form>
        <h3 className="d-inline-block mb-0 align-bottom">
          OnWear Member Sign Up
        </h3>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            className="form-control"
            placeholder="Username"
            id="userName"
          />
        </div>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            id="nameName"
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            id="email"
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            id="password"
          />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Re-password"
            id="password2"
          />
        </div>

        <div className="form-check">
          <br />
          <p className="fw-bold">
            I have read and agree to the following conditions:
          </p>
           {" "}
          <input
            type="checkbox"
            id="checkOne"
            name="conditionOne"
            value="one"
            onChange={() => {
              checkBox();
            }}
          />
            <label className="fw-bold">약관1 동의</label>
          <br /> {" "}
          <input
            type="checkbox"
            id="checkTwo"
            name="conditionTwo"
            value="two"
            onChange={() => {
              checkBox();
            }}
          />
            <label className="fw-bold">약관2 동의</label>
        </div>
        <br />
        <div>
          <span className="text-primary" onClick={toggle}>
            [필수] 이용약관 동의{" "}
            <h5 className="text-danger d-inline ">
              <BsFillXDiamondFill />
            </h5>
          </span>

          {terms === true ? (
            <div className="container pt-2">
              제1조(목적) 이 약관은 온웨어(이하 회사라 한다.)가 운영하는 온웨어
              온라인 사이트(이하 “사이트”이라 한다)에서 제공하는 제품 및
              잡화(이하 제품이라 한다.) 대여 서비스, 전자상거래 관련 서비스 등
              온라인 상의 제반 서비스(이하 “서비스”라 한다.)를 이용함에 있어
              회사와 회원의 권리의무 및 책임사항을 규정함을 목적으로 합니다...
              <span className="text-primary" onClick={toggle}>
                View Details{" "}
                <h5 className="text-danger d-inline ">
                  <BsFillArrowDownSquareFill />
                </h5>
              </span>
            </div>
          ) : (
            <div className="container pt-2">
              <p>
                제1조(목적) 이 약관은 온웨어(이하 회사라 한다.)가 운영하는
                온웨어 온라인 사이트(이하 “사이트”이라 한다)에서 제공하는 제품
                및 잡화(이하 제품이라 한다.) 대여 서비스, 전자상거래 관련 서비스
                등 온라인 상의 제반 서비스(이하 “서비스”라 한다.)를 이용함에
                있어 회사와 회원의 권리의무 및 책임사항을 규정함을 목적으로
                합니다.
              </p>
              <p>
                제2조(정의) ① “회사”란 온웨어 회사가 재화 또는 용역(이하 “재화
                등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등
                정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의
                영업장을 말하며, 아울러 온라인 사이트를 운영하는 사업자의
                의미로도 사용합니다. ② “이용자”란 “사이트”에 접속하여 이 약관에
                따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다. ③
                ‘회원’이라 함은 “사이트”에 회원등록을 한 자로서, 계속적으로
                회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다. ④
                ‘비회원’이라 함은 회원에 가입하지 않고 회사가 제공하는 서비스를
                이용하는 자를 말합니다.
              </p>
              <p>
                제3조 (약관 등의 명시와 설명 및 개정) ① 회사는 이 약관의 내용과
                상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할
                수 있는 곳의 주소를 포함), 전화번호, 우편주소, 사업자등록번호,
                통신판매업 신고번호, 개인정보보호책임자등을 이용자가 쉽게 알 수
                있도록 온라인 몰의 초기 서비스화면(전면)에 게시합니다. 다만,
                약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수
                있습니다. ② 회사는 이용자가 약관에 동의하기에 앞서 약관에
                정하여져 있는 내용 중 청약철회. 배송책임. 환불조건 등과 같은
                중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는
                팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다. ③ 회사는
                「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에
                관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」,
                「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한
                법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등
                관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. ④
                회사는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
                현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터
                적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게
                약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을
                두고 공지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게
                비교하여 이용자가 알기 쉽도록 표시합니다. ⑤ 회사는 약관을 개정할
                경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만
                적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의
                약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가
                개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한
                개정약관의 공지기간 내에 “사이트”에 송신하여 동의를 받은
                경우에는 개정약관 조항이 적용됩니다. ⑥ 이 약관에서 정하지 아니한
                사항과 이 약관의 해석에 관하여는 전자상거래 등에서의
                소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
                공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및
                관계법령 또는 상관례에 따릅니다.
              </p>
              <p>
                제4조(서비스의 제공 및 변경) ① 회사는 다음과 같은 업무를
                수행합니다. 1. 재화 또는 용역에 대한 정보 제공 및 구매계약의
                체결 2. 구매계약이 체결된 재화 또는 용역의 배송 3. 기타 온웨어가
                정하는 업무 ② 회사는 재화 또는 용역의 품절 또는 기술적 사양의
                변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는
                용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는
                용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의
                내용을 게시한 곳에 즉시 공지합니다. ③ 회사가 제공하기로 이용자와
                계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의
                변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지
                가능한 주소로 즉시 통지합니다. ④ 전항의 경우 회사는 이로 인하여
                이용자가 입은 손해를 배상합니다. 다만, 회사가 고의 또는 과실이
                없음을 입증하는 경우에는 아무런 책임을 부담하지 않습니다.
              </p>
              <p>
                제5조(서비스의 중단) ① 회사는 컴퓨터 등 정보통신설비의
                보수점검.교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는
                서비스의 제공을 일시적으로 중단할 수 있습니다. ② 회사는 제1항의
                사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는
                제3자가 입은 손해에 대하여 배상합니다. 단, 회사가 고의 또는
                과실이 없음을 입증하는 경우에는 배상하지 아니합니다. ③
                사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
                서비스를 제공할 수 없게 되는 경우에는 회사는 이전 30일 전부터
                공지합니다.
              </p>
              <p>
                제6조(회원가입) ① 이용자는 회사가 정한 가입 양식에 따라
                회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서
                회원가입을 신청합니다. ② 회사는 제1항과 같이 회원으로 가입할
                것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로
                등록합니다. 1. 가입신청자가 제7조 제2항에 의하여 이전에 회원
                자격을 상실한 적이 있는 경우(다만 회원자격 상실 후 회사의 회원
                재가입 승낙을 얻은 경우에는 예외로 함) 2. 실명이 아니거나 타인의
                명의를 이용한 경우 3. 등록 내용에 허위, 기재누락, 오기가 있는
                경우 4. 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이
                있다고 판단되는 경우 ③ 회원가입 계약의 성립시기는 회사가
                가입완료를 신청절차 상에서 표시한 시점으로 합니다.
              </p>
              <p>
                제7조(회원 탈퇴 및 자격 상실 등) ① 회원은 회사에 언제든지 탈퇴를
                요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다. ② 회원이
                다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및
                정지시킬 수 있습니다. 1. 가입 신청 시에 허위 내용을 등록한 경우
                2. “온웨어의 이용과 관련하여, 회원이 부담하는 채무 또는 책임을
                기일 내에 이행하지 않는 경우 3. 다른 사람의 온웨어 이용을
                방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                4. 온웨어를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
                반하는 행위를 하는 경우 ③ 회사가 회원 자격을 제한.정지 시킨 후,
                동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지
                아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다. ④ 회사가
                회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우
                회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의
                기간을 정하여 소명할 기회를 부여합니다.
              </p>
              <p>
                제8조(회원에 대한 통지) ① 회원에 대한 통지를 하는 경우, 회사는
                본 약관에 별도의 규정이 없는 한, 회원이 회사에 등록한 주소 또는
                전화번호로 전자우편 통보, 서신발송, 유선 통보 및 기타의 방법으로
                할 수 있습니다. ② 불특정다수 회원에 대한 통지의 경우 1주일이상
                온웨어 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.
                다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                대하여는 개별통지를 합니다.
              </p>
              <p>
                제9조 (대여 서비스) ① 회원은 온웨어에 가입했을 경우 온웨어
                사이트 제품 및 사이트에 게시된 대여 가능한 제품을 대여할 수
                있습니다. 회원은 제품 대여 주문시 입력한 수령 주소에서 온웨어
                직접 배송 혹은 온웨어와 계약한 제휴 업체(택배업체, 기타 물류
                업체 등)를 통해 인수합니다. ② 회원은 서비스를 통해 제품을 대여한
                후 반납을 원할 때는 온웨어가 규정한 절차에 따라 제품을 주문시
                입력한 반납 주소에서 제1항과 동일한 거래를 통해 제품을 밀봉한
                상태로 반납하여야 합니다. 만약 회원이 부득이한 사유로 입력한
                반납 주소 이외에서 혹은 다른 수단을 통해 제품을 반납해야 할
                경우에 추가 부대 비용이 발생했을 경우 해당 비용은 회원이
                지불합니다. 만약 반납일에 제품과 회원의 소재가 불명한 때에는
                패널티 3만원을 부과하고 비용은 등록결제 수단으로 자동
                결제됩니다. ③ 위 제1항 내지 2항 내용은 회원을 해지할 때까지
                적용됩니다. 회원은 해지했을 경우, 이용기간이 종료되는 날까지
                제2항과 같이 반납을 해야 합니다. 만약 공휴일등의 사유로 해당일에
                반납이 불가능 할 경우 종료일 후에 반납수거가 가능한 가장 가까운
                날로 반납일이 변경될 수 있습니다.
              </p>
              <p>
                제10조 (제품 손상 및 분실의 조치) ① 회원은 대여 중 제품의 손상
                또는 분실을 발견한 때에는 회사에 연락하고 그 처리방향에 대하여
                협의하여야 합니다. ② 회원의 고의ㆍ과실로 제품의 손상 또는 분실이
                발생한 경우 손해보상 비용은 제 조에 따릅니다. ③ 측정된 손해배상
                비용은 등록 결제 수단으로 자동 결제됩니다. ④ 제품 인수 이전의
                손상으로 인하여 제품을 사용 불능하게 되거나 수선이 필요하게
                되었을 때 회원은 회사로부터 대체 제품의 제공 또는 이에 준하는
                조치를 받을 수 있습니다. ⑤ 회원은 재화를 받은 후 "사이트"에
                표기되지 않은 훼손을 발견한 경우 24시간 내 회사로 신속히
                연락합니다. (고객센터, 이메일 등) ⑥ 제품의 사용을 시작하기 전에
                통보를 하지 않았을 경우, 해당 제품에 발생한 "사이트"에 표기되지
                않은 훼손 등에 관한 모든 책임은 회원에게 있으며, 회사는 사안의
                경중에 따라 회원에게 패널티 금액, 손해배상 등을 청구하거나
                사용정지 및 회원자격의 상실 등을 통보 할 수 있습니다. ⑦ 회원은
                제품을 분실했을 시 24시간 이내에 회사에 해당 사실을 전달 후,
                인근 파출소에 분실 신고를 해야 합니다.
              </p>
              <p>
                제11조 (제품 손상 및 분실에 대한 손해배상 요금 정책) ① 회원이
                회사의 제품을 대여하여 이용 중 손상 및 분실이 발생했을 경우 아래
                항의 기준에 따른 손해 배상을 해야 합니다. ② 제품의 손상 및
                분실에 따른 손해배상 기준은 다음과 같습니다. 1. 가벼운
                오염(비영구적 오염), 가벼운 생활 스크래치, 제품에 부착된
                부속품이 떨어졌지만 부속품을 회수한 상태 등 원상태로 복구 가능한
                손상의 경우 수선비용은 고객이 부담합니다. 2. 영구적인 오염, 제품
                원단 자체의 손상, 제품에 부착된 부속품이 떨어지고 부속품도
                분실한 상태, 제품과 함께 동봉된 부속품(장신구)이 분실된 상태 등
                원상태로 복구 불가능한 손상의 경우 회원은 회사에게 회사가 정한
                해당 제품의 판매 가격만큼의 비용을 지불하고 손상된 해당 물품에
                대한 소유권을 취득한다. 3. 제품을 분실하였을 경우 손해배상
                금액은 제2항 제2호를 따릅니다. ③ 본 서비스와 관련하여 발생한
                미납요금, 손해배상 등 회원의 회사에 대한 채무가 모두 정산될
                때까지, 해당 회원의 온웨어 서비스 사용이 중지되며, 그 채무가
                일정기간 계속하여 변제되지 않을 경우에는 해당 회원의 회원자격이
                박탈될 수 있습니다. 이러한 회원의 온웨어 사용권한에 대한 판단
                기준은 온웨어의 내부 운영 규정에 근거합니다. ④ 회사는 본
                서비스와 관련하여 발생한 미납요금, 페널티금액, 손해배상 등
                회원의 회사에 대한 채무가 정산되지 않을 경우 회원을 상대로
                보전처분, 본안소송제기, 강제집행절차의 착수 등 채무 변제를 위한
                법적 조치에 들어갈 수 있습니다.
              </p>
              <p>
                제12조 (제품 미 반환에 대한 조치) ① 회사는 회원이 제16조 제4항에
                따른 해지로 인해 이용기간 종료 시로부터 12시간을 경과하여도
                지정한 방법으로 제품을 반환하지 아니하거나 회사의 반환 청구에
                응하지 않을 때에는 제품 회수 및 손해보전에 필요한 모든 법적
                조치를 취할 수 있습니다. ② 회사는 제1항에 해당되는 경우 제품의
                소재를 확인하기 위하여 회원에게 전화를 하거나 주소지를 방문하여
                함께 거주하는 가족 및 친족 등에게 청취조사를 할 수 있습니다. ③
                회사는 제2항의 조치에도 불구하고 이용기간 종료 시로부터 24시간이
                경과하였음에도 제품과 회원의 소재가 불명한 때에는 도난신고 등
                필요한 조치를 취할 수 있습니다. 이 경우 회사는 제2항에 따른
                조치를 취하였음에도 제품과 회원의 소재가 불명함을 입증하여야
                합니다. ④ 회사는 제2항의 조치에도 불구하고 이용기간 종료
                시로부터 48시간이 경과한 뒤 소재가 불명하고 연락이 닿지 않을
                경우, 점유하고 있는 제품의 내부 보상금액을 등록결제 수단으로
                자동 결제 합니다. ⑤ 회원은 제1항 내지 제3항, 제 4항에 해당하는
                경우 회사에 입힌 손해를 배상할 책임을 지며, 제품 회수 및 회원의
                소재확인 등에 소요된 비용을 부담하여야 합니다. ⑥ 회사가 제1항
                내지 제3항, 제 4항에 의한 업무를 처리하는 과정에서 고의 또는
                과실로 회원에게 손해를 입힌 경우에는 배상책임을 부담합니다. ⑦
                회사는 제2항의 조치를 통해 고객이 연락을 응했을 때 고객에게 통보
                후 연체일 1일당 1만원의 연체료를 부과하고 비용은 등록결제
                수단으로 자동 결제됩니다.
              </p>
              <p>
                제13조(구매신청) ① 회원은 “사이트”상에서 다음 또는 이와 유사한
                방법에 의하여 구매를 신청하며, 이용자가 구매신청을 함에 있어서
                다음의 각 내용을 알기 쉽게 제공하여야 합니다. 1. 재화 등의 검색
                및 선택 2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는
                이동전화번호) 등의 입력 3. 약관내용, 청약철회권이 제한되는
                서비스, 배송료. 등의 비용부담과 관련한 내용에 대한 확인 4. 이
                약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예,
                마우스 클릭) 5. 재화 등의 구매신청 및 이에 관한 확인 또는 회사의
                확인에 대한 동의 6. 결제방법의 선택 ② 회사가 제3자에게 구매자
                개인정보를 제공•위탁할 필요가 있는 경우 실제 구매신청 시
                구매자의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를
                받지 않습니다. 이 때 회사는 제공되는 개인정보 항목, 제공받는 자,
                제공받는 자의 개인정보 이용 목적 및 보유•이용 기간 등을
                구매자에게 명시하여야 합니다. 다만 「정보통신망이용촉진 및
                정보보호 등에 관한 법률」 제25조 제1항에 의한 개인정보
                처리위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에
                따릅니다.
              </p>
              <p>
                제14조 (구매계약의 성립) ① 회사는 제13조와 같은 구매신청에
                대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만,
                만19세 이하의 경우, 본장인 “구매계약”서비스를 이용할 수
                없습니다. 1. 신청 내용에 허위, 기재누락, 오기가 있는 경우 2.
                기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고
                판단하는 경우 3. 기타 제반 법령 및 정부의 가이드라인에 위반되는
                경우 ② 회사의 승낙이 수신확인통지형태로 이용자에게 도달한 시점에
                계약이 성립한 것으로 봅니다. ③ 회사의 승낙의 의사표시에는
                이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정
                취소 등에 관한 정보 등을 포함하여야 합니다.
              </p>
              <p>
                제15조(지급방법) ① 회사에서 구매한 재화 또는 용역에 대한
                대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수
                있습니다. 단, 회사는 이용자의 지급방법에 대하여 재화 등의 대금에
                어떠한 명목의 수수료도 추가하여 징수할 수 없습니다. 1. 직불카드,
                신용카드 등의 각종 카드 결제 2. 회사가 지급한 포인트에 의한 결제
                3. 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제 4.
                기타 전자적 지급 방법에 의한 대금 지급 등 ② 구매대금의 결제와
                관련하여 회원이 입력한 정보 및 그와 관련된 책임은 회원에게
                있으며, 재화 또는 용역의 청약 후 합리적인 일정 기간 내에 결제가
                이루어 지지 않는 경우 회사는 이에 해당주문을 취소할 수 있습니다.
                ③ 회사는 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를
                확인할 수 있으며 필요한 경우 해당 거래진행의 정지 및 소명자료의
                제출을 요청할 수 있습니다.
              </p>
              <p>
                제16조(수신확인통지.구매신청 변경 및 취소) ① 회사는 이용자의
                구매신청이 있는 경우 이용자에게 수신확인통지를 합니다. ②
                수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는
                경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를
                요청할 수 있고 회사는 배송 전에 이용자의 요청이 있는 경우에는
                지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을
                지불한 경우에는 제19조의 청약철회 등에 관한 규정에 따릅니다.
              </p>
              <p>
                제17조(재화 등의 공급) ① 회사는 이용자와 재화 등의 공급시기에
                관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일
                이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의
                필요한 조치를 취합니다. 다만, 회사가 이미 재화 등의 대금의 전부
                또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터
                3영업일 이내에 조치를 취합니다. 이때 회사는 이용자가 재화 등의
                공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
                ② 회사는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용
                부담자, 수단별 배송기간 등을 명시합니다. 만약 회사가 약정
                배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야
                합니다. 다만 회사가 고의.과실이 없음을 입증한 경우에는 그러하지
                아니합니다. ③ 공휴일 및 기타 휴무일 또는 천재지변 등의
                불가항력적 사유가 발생하는 경우 그 해당 기한은 배송소요기간에서
                제외합니다.
              </p>
              <p>
                제18조(환급) 회사는 이용자가 구매 신청한 재화 등이 품절 등의
                사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를
                이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을
                받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를
                취합니다.
              </p>
              <p>
                제19조(청약철회 등) ① 온웨어의 재화 및 물품 구매에 관한 계약을
                체결한 회원은 주문 확인의 통지를 받은 날로부터 1일 이내에 청약의
                철회를 할 수 있습니다. 그 외 2일 이상 소요가 된 뒤는 환불이
                불가합니다.“다만, 청약철회에 관하여 「전자상거래 등에서의
                소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법
                규정에 따릅니다. ② 이용자는 재화 등을 배송 받은 경우 다음 각
                호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다. 1.
                이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
                경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한
                경우에는 청약철회를 할 수 있습니다) 2. 이용자의 사용 또는 일부
                소비에 의하여 재화 등의 가치가 현저히 감소한 경우 3. 시간의
                경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히
                감소한 경우 4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우
                그 원본인 재화 등의 포장을 훼손한 경우 ③ 제2항 제2호 내지
                제4호의 경우에 회사가 사전에 청약철회 등이 제한되는 사실을
                소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는
                등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지
                않습니다. ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의
                내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행된 때에는
                당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알
                수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
              </p>
              <p>
                제20조(청약철회 등) ① 회사는 이용자로부터 재화 등을 반환받은
                경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다.
                이 경우 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에
                대하여 「전자상거래 등에서의 소비자보호에 관한 법률
                시행령」제21조의2에서 정하는 지연이자율(괄호 부분 삭제)을 곱하여
                산정한 지연이자를 지급합니다. ② 회사는 위 대금을 환급함에 있어서
                이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의
                대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로
                하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은
                이용자가 부담합니다. 회사는 이용자에게 청약철회 등을 이유로
                위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이
                표시•광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회
                등을 하는 경우 재화 등의 반환에 필요한 비용은 회사는가
                부담합니다. ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한
                경우에 회사는 청약철회 시 그 비용을 누가 부담하는지를 이용자가
                알기 쉽도록 명확하게 표시합니다.
              </p>
              <p>
                제21조(개인정보보호) ① 회사는 이용자의 개인정보 수집 시
                서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를
                수집합니다. ② 회사는 회원가입 시 구매계약이행에 필요한 정보를
                미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여
                구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정
                개인정보를 수집하는 경우에는 그러하지 아니합니다. ③ 회사는
                이용자의 개인정보를 수집•이용하는 때에는 당해 이용자에게 그
                목적을 고지하고 동의를 받습니다. ④ 회사는 수집된 개인정보를 목적
                외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는
                제3자에게 제공하는 경우에는 이용•제공단계에서 당해 이용자에게 그
                목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이
                있는 경우에는 예외로 합니다. ⑤ 회사가 제2항과 제3항에 의해
                이용자의 동의를 받아야 하는 경우에는 개인정보보호 책임자의
                신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및
                이용목적, 제3자에 대한 정보제공 관련사항(제공 받은 자, 제공목적
                및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에
                관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야
                하며 이용자는 언제든지 이 동의를 철회할 수 있습니다. ⑥ 이용자는
                언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및
                오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한
                조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한
                경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지
                않습니다. ⑦ 회사는 개인정보 보호를 위하여 이용자의 개인정보를
                처리하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을
                포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자
                제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을
                집니다. ⑧ 회사 또는 그로부터 개인정보를 제공받은 제3자는
                개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
                개인정보를 지체 없이 파기합니다. ⑨ 회사는 개인정보의
                수집•이용•제공에 관한 동의란을 미리 선택한 것으로 설정해두지
                않습니다. 또한 개인정보의 수집•이용•제공에 관한 이용자의
                동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이
                아닌 개인정보의 수집•이용•제공에 관한 이용자의 동의 거절을
                이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
              </p>
              <p>
                제22조(회사의 의무) ① 회사는 법령과 이 약관이 금지하거나
                공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
                지속적이고, 안정적으로 재화.용역을 제공하는데 최선을 다하여야
                합니다. ② 회사는 이용자가 안전하게 인터넷 서비스를 이용할 수
                있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
                갖추어야 합니다. ③ 회사는 상품이나 용역에 대하여 「표시.광고의
                공정화에 관한 법률」 제3조 소정의 부당한 표시.광고행위를
                함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
                ④ 회사는 이용자가 원하지 않는 영리목적의 광고성 전자우편을
                발송하지 않습니다.
              </p>
              <p>
                제23조(회원의 ID 및 비밀번호에 대한 의무) ① 제17조의 경우를
                제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다. ②
                회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                안됩니다. ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가
                사용하고 있음을 인지한 경우에는 바로 통보하고 안내가 있는
                경우에는 그에 따라야 합니다.
              </p>
              <p>
                제24조(회원의 의무) 회원은 다음 행위를 하여서는 안 됩니다. 1.
                서비스 이용 관련 제반 신청 또는 변경 행위시 허위내용 기재행위 2.
                타인의 정보를 도용하는 행위 3. 온웨어에 게시된 각종 정보의 무단
                변경, 삭제 등 훼손행위 4. 회사가 허용한 정보 이외의 다른
                정보(컴퓨터 프로그램 및 광고 등)를 송신하거나 게시하는 행위 5.
                회사 기타 제3자의 저작권 등 지적재산권에 대한 침해행위 6. 회사
                기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 7. 외설
                또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를
                온웨어 사이트에 공개 또는 게시하는 행위 ② 회원은 관련 법령, 본
                약관의 규정, 서비스 관련 이용안내 및 주의사항, 회사가 통지한
                사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를
                하여서는 안 됩니다.
              </p>
              <p>
                제25조(저작권의 귀속 및 이용제한) ① 회사가 작성한 저작물에 대한
                저작권 기타 지적재산권은 회사에 귀속합니다. ② 이용자는
                “사이트”를 이용함으로써 얻은 정보 중 회사에게 지적재산권이
                귀속된 정보를 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타
                방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
                하여서는 안됩니다. ③ 회사는 약정에 따라 이용자에게 귀속된
                저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
              </p>
              <p>
                제26조(분쟁해결) ① 회사는 이용자가 제기하는 정당한 의견이나
                불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
                설치.운영합니다. ② 회사는 이용자로부터 제출되는 불만사항 및
                의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
                곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해
                드립니다. ③ 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여
                이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
                시•도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
              </p>
              <p>
                제27조(재판권 및 준거법) ① 회사와 이용자 간에 발생한 전자상거래
                분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가
                없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
                다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
                거주자의 경우에는 민사소송법상의 관할법원에 제기합니다. ② 회사와
                이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다. 부
                칙(시행일) 이 약관은 2022년 3월 14일부터 시행합니다.
              </p>
              <p className="text-primary fw-bold">
                [필수] 개인정보 수집 및 이용 동의{" "}
                <h5 className="text-danger d-inline ">
                  <BsFillXDiamondFill />
                </h5>
              </p>
              <p>1. 개인정보 수집목적 및 이용목적</p>
              <p>
                가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
              </p>
              <p>
                콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송 ,
                금융거래 본인 인증 및 금융 서비스
              </p>
              <p>나. 회원 관리</p>
              <p>
                회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정
                이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 ,
                만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인,
                불만처리 등 민원처리 , 고지사항 전달
              </p>
              <p>
                2. 수집하는 개인정보 항목 : 이름 , 로그인ID , 비밀번호 , 이메일
                , 14세미만 가입자의 경우 법정대리인의 정보
              </p>
              <p>3. 개인정보의 보유기간 및 이용기간</p>
              <p>
                원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당
                정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
                이유로 명시한 기간 동안 보존합니다.
              </p>
              <p>가. 회사 내부 방침에 의한 정보 보유 사유</p>
              <p>o 부정거래 방지 및 쇼핑몰 운영방침에 따른 보관 : OO년</p>
              <p>나. 관련 법령에 의한 정보보유 사유</p>
              <p>o 계약 또는 청약철회 등에 관한 기록</p>
              <p>-보존이유 : 전자상거래등에서의소비자보호에관한법률</p>
              <p>-보존기간 : 5년</p>
              <p>o 대금 결제 및 재화 등의 공급에 관한 기록</p>
              <p>-보존이유: 전자상거래등에서의소비자보호에관한법률</p>
              <p>-보존기간 : 5년</p>
              <p>o 소비자 불만 또는 분쟁처리에 관한 기록</p>
              <p>-보존이유 : 전자상거래등에서의소비자보호에관한법률</p>
              <p>-보존기간 : 3년</p>
              <p>o 로그 기록</p>
              <p>-보존이유: 통신비밀보호법</p>
              <p>-보존기간 : 3개월</p>
              <p>※ 동의를 거부할 수 있으나 거부시 회원 가입이 불가능합니다.</p>
              <p>
                {" "}
                할인쿠폰 및 혜택, 이벤트, 신상품 소식 등 쇼핑몰에서 제공하는
                유익한 쇼핑정보를 SMS나 이메일로 받아보실 수 있습니다.
              </p>
              <span className="text-primary" onClick={toggle}>
                Close{" "}
                <h5 className="text-danger d-inline ">
                  <BsFillArrowUpSquareFill />
                </h5>
              </span>
            </div>
          )}

          <br />
          <br />
        </div>

        <div className="  text-center">
          {/* <button
            type="button"
            className="btn btn-lg btn-outline-success btn-block "
            onClick={sendData}
          >
            Sign Up
          </button> */}
          <Button
            variant="outline-success"
            size="lg"
            className="mb-2"
            onClick={sendData}
            id="SignUpBtn"
            {...disableBtnProps}
          >
            <BsFillPersonCheckFill />
            &nbsp; Sign Up
          </Button>{" "}
          <Button
            variant="warning"
            size="lg"
            className="mb-2 text-dark"
            {...disableBtnProps}
          >
            <SocialLogin />
          </Button>
          <br />
          <br />
          {/* <p className="forgot-password text-right">
            Already registered <a href="#">sign in?</a>
          </p> */}
        </div>
      </form>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default SignUp;
